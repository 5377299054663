var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-green" },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("mail_outline")])],
                1
              ),
              _c("h4", { staticClass: "title" }, [_vm._v("Register Form")]),
            ]
          ),
          _c(
            "md-card-content",
            [
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid": !_vm.errors.has("email") && _vm.touched.email,
                    },
                    { "md-error": _vm.errors.has("email") },
                  ],
                },
                [
                  _c("label", [_vm._v("Email Adress")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.email,
                        expression: "modelValidations.email",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "email",
                      type: "email",
                      name: "email",
                      required: "",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.errors.has("email") && _vm.touched.email,
                              expression:
                                "errors.has('email') && touched.email",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("email") && _vm.touched.email,
                              expression:
                                "!errors.has('email') && touched.email",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("password") && _vm.touched.password,
                    },
                    { "md-error": _vm.errors.has("password") },
                  ],
                },
                [
                  _c("label", [_vm._v("Password")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.password,
                        expression: "modelValidations.password",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "password",
                      type: "password",
                      name: "password",
                      required: "",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.errors.has("password") &&
                                _vm.touched.password,
                              expression:
                                "errors.has('password') && touched.password",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("password") &&
                                _vm.touched.password,
                              expression:
                                "!errors.has('password') && touched.password",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "md-field",
                {
                  class: [
                    {
                      "md-valid":
                        !_vm.errors.has("confirmPassword") &&
                        _vm.touched.confirmPass,
                    },
                    { "md-error": _vm.errors.has("confirmPassword") },
                  ],
                },
                [
                  _c("label", [_vm._v("Confirm Password")]),
                  _c("md-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.modelValidations.confirmPassword,
                        expression: "modelValidations.confirmPassword",
                      },
                    ],
                    attrs: {
                      "data-vv-name": "confirmPassword",
                      type: "password",
                      name: "confirmPassword",
                      required: "",
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.errors.has("confirmPassword") &&
                                _vm.touched.confirmPassword,
                              expression:
                                "errors.has('confirmPassword') && touched.confirmPassword",
                            },
                          ],
                          staticClass: "error",
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "slide-y-down-transition",
                    [
                      _c(
                        "md-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.errors.has("confirmPassword") &&
                                _vm.touched.confirmPassword,
                              expression:
                                "!errors.has('confirmPassword') && touched.confirmPassword",
                            },
                          ],
                          staticClass: "success",
                        },
                        [_vm._v("done")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "form-category" }, [
                _vm._v("* Required fields"),
              ]),
            ],
            1
          ),
          _c(
            "md-card-actions",
            { attrs: { "md-alignment": "space-between" } },
            [
              _c(
                "md-checkbox",
                {
                  model: {
                    value: _vm.boolean,
                    callback: function ($$v) {
                      _vm.boolean = $$v
                    },
                    expression: "boolean",
                  },
                },
                [_vm._v("Subscribe to newsletter")]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-success",
                  attrs: { "native-type": "submit" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.validate.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Register")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }