var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [_c("register-form", { on: { "on-submit": _vm.onRegisterSubmit } })],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [_c("login-form", { on: { "on-submit": _vm.onLoginSubmit } })],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c("type-validation-form", {
          on: { "on-submit": _vm.onTypeValidationSubmit },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c("range-validation-form", {
          on: { "on-submit": _vm.onRangeValidationSubmit },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }